@import "bourbon/bourbon";
@import "normalize";
// @import "grid";

// rgb(150, 73, 60)
$main-color: rgb(131, 184, 253);
$body-bg-color: rgb(46, 48, 54);
$text-color: rgb(217, 225, 235);

body {
  background-color: $body-bg-color;
  color: $text-color;
  font-family: "Roboto Mono", monospace;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 300;
}

mark {
  background-color: rgba(rgb(245, 220, 80), .8);
  padding: 0 4px;
}

b,
strong {
  font-weight: bold;
  font-weight: 700;
}

p {
  margin-top: 0.3em;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Lato", sans-serif;
  font-size: 18px;
}

.heading {
  padding: 2px 1em 4px 2em;
  display: inline-block;
  margin-left: -1em;
  color: $body-bg-color;
  background-color: darken($text-color, 10%);
}

ul {
  list-style: none;
  padding: 0 2em 0;
  li {
    padding: 0;
    position: relative;
    margin: 0.5em 0 1em;
  }
}

li::before {
  content: "•";
  color: $main-color;
  left: -1.5em;
  position: absolute;
  font-family: "Lato", sans-serif;
  font-size: 100%;
}

li:target {
  outline: none;
  border: 2px dashed rgba($text-color, 0.2);
  border-radius: 4px;
  padding: 1em;
  margin-left: -1em;
  margin-right: -1em;
  &::before {
    content: "";
  }
}

#notes li {
  margin-bottom: 2rem;
}

a {
  font-weight: 700;
  font-family: "Roboto Mono", monospace;
  padding-bottom: 1px;
  text-decoration: none;
  color: $text-color;
  box-shadow: 0 2px 0 $main-color;
  letter-spacing: .5px;

  &:hover {
    box-shadow: 0 0 0;
  }
}

code {
  font-family: "Roboto Mono", monospace;
}

blockquote {
  padding-left: 1em;
  margin-left: 1.5em;
  border-left: 2px solid rgba(white, .2);
}

.wrapper {
  max-width: 600px;
  margin: 1em auto;
  padding: 1em;
}

@media (min-width: 576px) {
  body {
    zoom: 125%;
  }
  .heading {
    padding: 2px 1em 4px;
    margin-left: 0;
  }
}

.small {
  font-size: 11px;
  font-family: "Roboto Mono", monospace;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}
.absolute.left {
  top: 0;
  left: 1em;
  line-height: 2rem;
}

.feed {
  text-align: right;
  margin-bottom: -.6em;
  opacity: 0.66;
  
  a {
    font-weight: normal;
    box-shadow: 0 0 0;
    padding: .5em 1em;
  }
  &:hover {
    opacity: 1;
  }
}

.sub {
  margin-top: .33em;
}

.centered {
  text-align: center;
  display: block;
}

.notes-stream img {
  vertical-align: bottom;
}

section {
  margin: 2.5em 0 1em;
  position: relative;
}

main section:first-child {
  margin-top: 0;
}

.no-underline {
  text-decoration: none;
  box-shadow: 0 0 0;
}
